// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import permessi from "@/config/permessi";
import { AnagraficaDistributoreEdit } from "@/models/anafrafica/AnagraficaDistributore";
import { AnagraficaLingue } from "@/models/anafrafica/AnagraficaLingue";
import { AnagraficaProvince } from "@/models/anafrafica/AnagraficaProvince";
import { CodiciPagamento } from "@/models/anafrafica/codiciPagamento";
import { utenteEdit } from "@/models/anafrafica/utenti";
import { column, customButtons, customEventItem } from "@/models/grid/columnModels";
import { Filter } from "@/models/grid/filter";
import { TextValueItem } from "@/models/TextValueItem";
import { ForzaCambioPasswordModel } from "@/models/userModels";
import api from "@/services/api";
import { Component, Prop, Watch } from "vue-property-decorator";

const _ENDPONT = api.endpoint.MY_OVERINS.UTENTI;

@Component
export default class TuoiUtentiPage extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.WEB.MyWebins.UTENTI];
	}
	
	province: Array<TextValueItem> = [];
	distributori: Array<TextValueItem> = [];
	generi: Array<TextValueItem> = [
		{ text: "Femmina", value: "F" },
		{ text: "Maschio", value: "M" }
	];
	commerciali: Array<TextValueItem> = [];
	fornitori: Array<TextValueItem> = [];
	ruoli: Array<TextValueItem> = [];

	editItem: utenteEdit = new utenteEdit();
	showEditModal: boolean = false;
	loading: boolean = true;
	currentItem: any = {};
	showOnlyActive: boolean = false;
	showRecuperoPasswordModal: boolean = false;
	cambioPwd: ForzaCambioPasswordModel = new ForzaCambioPasswordModel();
	showPasswordNuova: boolean = false;
	showConfermaPassword: boolean = false;

	get distributoreFisso(): boolean {
		return this.distributoreID != null;
	}
	get fornitoreFisso(): boolean {
		return this.fornitoreID != null;
	}
	get columns(): Array<any> {
		return [
			{
				field: "itemID",
				headerCell: "myHeaderCellTemplate",
				title: "ID",
				width: 20,
				filterable: false,
				hidden: true,
				editHide: true
			},
			{
				field: "username",
				editOrder: -330,
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.utente").toString(),
				export: true
			},
			{
				field: "ruoliID",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.tc("generico.ruolo_i", 2).toString(),
				editOrder: -310,
				editType: "multi-select",
				editSource: this.ruoli,
				editSourceText: "text",
				editSourceValue: "value",
				hidden: true
			},
			{
				field: "ruoli",
				editHide: true,
				hidden: false,
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.tc("generico.ruolo_i", 2).toString(),
				groupable: false,
				export: true
			},
			{
				field: "nome",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.nome").toString(),
				width: 200,
				attributes: {
					class: "rsClick",
					style: "font-weight: bold;"
				},
				export: true
			},
			{
				field: "cognome",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.cognome").toString(),
				width: 200,
				attributes: {
					class: "rsClick",
					style: "font-weight: bold;"
				},
				export: true
			},
			{
				field: "fullname",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.fullName").toString(),
				width: 300,
				attributes: {
					class: "rsClick",
					style: "font-weight: bold;"
				},
				export: true,
				editDisabled: true
			},
			{
				field: "email",
				editOrder: 300,
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.email").toString(),
				width: 300,
				export: true
			},
			{
				field: "attivo",
				type: "boolean",
				filterType: "boolean",
				disabled:true,
				editOrder: 310,
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.attivo").toString(),
				width: 100
			},
			{
				field: "_actions",
				title: " ",
				width: 150,
				cell: "defaultCellTemplate",
				type: "actions_list",
				filterable: false,
				headerCell: "myHeaderCellTemplate",
				customButtons: [
					{ command: "recuperoPwd", label: "CP", visible: this.canEdit }
				]
			}
		];
	}

	get columnsMobile(): Array<any> {
		return [
			{
				field: "itemID",
				headerCell: "myHeaderCellTemplate",
				title: "ID",
				width: 20,
				filterable: false,
				hidden: true,
				editHide: true
			},
			{
				field: "username",
				editOrder: -330,
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.utente").toString(),
				export: true,
				width: 150
			},
			{
				field: "ruoliID",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.tc("generico.ruolo_i", 2).toString(),
				editOrder: -310,
				editType: "multi-select",
				editSource: this.ruoli,
				editSourceText: "text",
				editSourceValue: "value",
				filterable: false,
				hidden: true
			},
			{
				field: "ruoli",
				editHide: true,
				hidden: false,
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.tc("generico.ruolo_i", 2).toString(),
				groupable: false,
				export: true,
				width: 200
			},
			{
				field: "fullname",
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.fullName").toString(),
				width: 300,
				attributes: {
					class: "rsClick",
					style: "font-weight: bold;"
				},
				export: true,
				editDisabled: true
			},
			{
				field: "email",
				editOrder: 300,
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.email").toString(),
				width: 300,
				export: true
			},
			{
				field: "attivo",
				type: "switch",
				editOrder: 310,
				headerCell: "myHeaderCellTemplate",
				title: this.$i18n.t("generico.attivo").toString(),
				width: 100
			},
			{
				field: "_actions",
				title: " ",
				width: 150,
				cell: "defaultCellTemplate",
				type: "actions_list",
				filterable: false,
				headerCell: "myHeaderCellTemplate",
				customButtons: [
					{ command: "recuperoPwd", label: "CP", visible: this.canEdit }
				]
			}
		];
	}
	// testVisible(item: any) {
	// 	return true;
	// }
	// testDisable(item: any) {
	// 	return false;
	// }
	// sort: any = [{ field: "cognome", dir: "asc" }];

	created() {
		if (!(this.isAppMobile == false)) {
			this.pageable["buttonCount"] = 3;
			this.pageable["info"] = false;
			this.pageable["pageSizes"] = undefined;
		}
	}

	mounted() {
		var self = this;
		var p = [
			// api.getDatiDropDown(api.endpoint.DROPDOWN.DISTRIBUTORI).then(res => (self.distributori = res)),
			// api.getDatiDropDown(api.endpoint.DROPDOWN.FORNITORI).then(res => (self.fornitori = res)),
			// api.getDatiDropDown(api.endpoint.DROPDOWN.COMMERCIALI).then(res => (self.commerciali = res)),
			// api.getDatiDropDown(api.endpoint.DROPDOWN.PROVINCE).then(res => (self.province = res)),
			api.getDatiDropDown(api.endpoint.DROPDOWN.RUOLI_WEB).then(res => (self.ruoli = res))
		];

		Promise.all(p)
			.then(responses => {
				this.getData();
				self.loading = false;
			})
			.catch(err => {
				console.log(err);
			});
	}

	@Watch("showRecuperoPasswordModal")
	onShowCambioPasswordModalChange(newValue: boolean, oldValue: boolean) {
		this.cambioPwd = new ForzaCambioPasswordModel();
	}

	getData() {
		var self = this;
		self.getGridData(_ENDPONT);
	}

	onCustomButtonClick(eventCode: customEventItem) {
		var self = this;
		var item = eventCode.item;
		if (eventCode.command == "recuperoPwd") {
			self.editItem = Object.assign(new utenteEdit(), item);

			if (item.nome && item.nome != "") {
				self.editSubTitle = item.nome + " " + item.cognome;
			} else self.editSubTitle = item.cognome;
			self.editTitle = item.username;
			self.showRecuperoPasswordModal = true;
		}
	}

	exportExcel() {
		//prima request a api e poi esport
		this.exportGridExcel(_ENDPONT);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", {
			domProps: { innerHTML: `<strong>${item.username}</strong><h5>${item.cognome || ""} ${item.nome || ""}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` }
		}); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.tc("generico.utente_i", 1)]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}
	addNewRow() {
		var u = new utenteEdit();
		this.onEdit(u);
	}
	onEdit(item: any) {
		var self = this;

		api.getUtentiRuoli(item.itemID).then(res => {
			self.editItem = Object.assign(new utenteEdit(), item);
			self.editItem.ruoliID = res;

			if (item.nome && item.nome != "") {
				self.editSubTitle = item.nome + " " + item.cognome;
			} else self.editSubTitle = item.cognome;
			self.editTitle = item.username;
			self.showEditModal = true;
		});
	}
	editTitle: string = null;
	editSubTitle: string = null;
	get isNewItem(): boolean {
		var self = this;
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.tc("generico.utente_i", 1) }).toString();
		self.setGridRow<utenteEdit>(_ENDPONT, self.editItem, title, message).then(res => (self.showEditModal = false));
	}

	onCambiaPasswordConferma() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.t("msg.passwordCambiata").toString();
		self.cambioPwd.username = self.editItem.username;
		api.doForzaCambiaPassword(self.cambioPwd).then(token => {
			self.$bvToast.toast(message, {
				variant: "success",
				title: title,
				solid: true
			});
			this.showRecuperoPasswordModal = false;
		});
	}

	showfilterDialog: boolean = false;

	onApplyFiltersMobile(filtersMobile: Filter) {
		this.filter = filtersMobile;
		this.onFilterMobileApply();
		this.onCloseDialogMessage();
	}
	onRemoveAllFilters() {
		this.clearSortAndFilter();
		//this.onCloseDialogMessage();
	}

	onCloseDialogMessage() {
		this.showfilterDialog = false;
	}

	onOpenFilterDialog() {
		this.showfilterDialog = true;
	}
}
